import React, { Component } from "react";
import { Button } from "react-bootstrap";
import {
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel
} from "@mui/material";
import {
    updateAnalyte,
    createAnalyte,
    getAnalyteDataById
} from "../../../../services/clinicPortalServices/analyteService.js";
import {
    specimenTypes,
    sampleTypes
} from "../../../../services/common/optionsData.js";

export default class AnalyteDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            analyteId:
                this.props && this.props.analyteId ? this.props.analyteId : "",
            name: "",
            loincCode: "",
            units: "",
            resultType: "",
            method: "",
            instrument: "",
            turnaroundTime: "",
            cost: "",
            clinicalSignificance: "",
            specimenType: "",
            sampleType: "",
            storageTransport: "",
            referenceRanges: [],
            criticalValues: [],
            cptCodes: [],
            synonyms: [],
            interferences: [],
            errors: []
        };
    }

    componentDidMount() {
        if (this.state.analyteId) {
            this.loadDetails();
        }
    }

    loadDetails = () => {
        getAnalyteDataById(this.state.analyteId).then((res) => {
            const analyteInfo = res.data;
            this.setState({
                name: analyteInfo.name,
                loincCode: analyteInfo.loincCode,
                units: analyteInfo.units,
                resultType: analyteInfo.resultType,
                method: analyteInfo.method,
                instrument: analyteInfo.instrument,
                turnaroundTime: analyteInfo.turnaroundTime,
                cost: analyteInfo.cost,
                clinicalSignificance: analyteInfo.clinicalSignificance,
                specimenType: analyteInfo.specimenType,
                sampleType: analyteInfo.sampleType,
                storageTransport: analyteInfo.storageTransport,
                referenceRanges:
                    analyteInfo.referenceRanges &&
                    analyteInfo.referenceRanges.length
                        ? analyteInfo.referenceRanges
                        : [],
                criticalValues:
                    analyteInfo.criticalValues &&
                    analyteInfo.criticalValues.length
                        ? analyteInfo.criticalValues
                        : [],
                cptCodes:
                    analyteInfo.cptCodes && analyteInfo.cptCodes.length
                        ? analyteInfo.cptCodes
                        : [],
                synonyms:
                    analyteInfo.synonyms && analyteInfo.synonyms.length
                        ? analyteInfo.synonyms
                        : [],
                interferences:
                    analyteInfo.interferences &&
                    analyteInfo.interferences.length
                        ? analyteInfo.interferences
                        : []
            });
        });
    };

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
        if (e.target.name === "specimenType") {
            this.setState({ sampleType: "" });
        }
    };

    addArrayItem = (type) => {
        if (type === "referenceRanges") {
            let referenceRanges = this.state.referenceRanges;
            referenceRanges.push({
                sex: "",
                ageGroup: "",
                unit: "",
                startRange: "",
                endRange: ""
            });
            this.setState({
                referenceRanges: referenceRanges
            });
        } else if (type === "criticalValues") {
            let criticalValues = this.state.criticalValues;
            criticalValues.push({
                sex: "",
                ageGroup: "",
                unit: "",
                lowValue: "",
                lowOperator: "",
                highValue: "",
                highOperator: ""
            });
            this.setState({
                criticalValues: criticalValues
            });
        } else if (type === "cptCodes") {
            let cptCodes = this.state.cptCodes;
            cptCodes.push("");
            this.setState({
                cptCodes: cptCodes
            });
        } else if (type === "synonyms") {
            let synonyms = this.state.synonyms;
            synonyms.push("");
            this.setState({
                synonyms: synonyms
            });
        } else if (type === "interferences") {
            let interferences = this.state.interferences;
            interferences.push("");
            this.setState({
                interferences: interferences
            });
        }
    };

    updateArrayItem = (value, index, type, subProperty) => {
        if (type === "referenceRanges") {
            let referenceRanges = this.state.referenceRanges;
            this.setState({
                referenceRanges: referenceRanges.map((item, i) => {
                    if (i === index) {
                        item[subProperty] = value;
                    }
                    return item;
                })
            });
        } else if (type === "criticalValues") {
            let criticalValues = this.state.criticalValues;
            this.setState({
                criticalValues: criticalValues.map((item, i) => {
                    if (i === index) {
                        item[subProperty] = value;
                    }
                    return item;
                })
            });
        } else if (type === "cptCodes") {
            let cptCodes = this.state.cptCodes;
            this.setState({
                cptCodes: cptCodes.map((item, i) => {
                    if (i === index) {
                        item = value;
                    }
                    return item;
                })
            });
        } else if (type === "synonyms") {
            let synonyms = this.state.synonyms;
            this.setState({
                synonyms: synonyms.map((item, i) => {
                    if (i === index) {
                        item = value;
                    }
                    return item;
                })
            });
        } else if (type === "interferences") {
            let interferences = this.state.interferences;
            this.setState({
                interferences: interferences.map((item, i) => {
                    if (i === index) {
                        item = value;
                    }
                    return item;
                })
            });
        }
    };

    removeArrayItem = (index, type) => {
        if (type === "referenceRanges") {
            let referenceRanges = this.state.referenceRanges;
            referenceRanges.splice(index, 1);
            this.setState({
                referenceRanges: referenceRanges
            });
        } else if (type === "criticalValues") {
            let criticalValues = this.state.criticalValues;
            criticalValues.splice(index, 1);
            this.setState({
                criticalValues: criticalValues
            });
        } else if (type === "cptCodes") {
            let cptCodes = this.state.cptCodes;
            cptCodes.splice(index, 1);
            this.setState({
                cptCodes: cptCodes
            });
        } else if (type === "synonyms") {
            let synonyms = this.state.synonyms;
            synonyms.splice(index, 1);
            this.setState({
                synonyms: synonyms
            });
        } else if (type === "interferences") {
            let interferences = this.state.interferences;
            interferences.splice(index, 1);
            this.setState({
                interferences: interferences
            });
        }
    };

    hasError = (key) => {
        return this.state.errors.indexOf(key) !== -1;
    };

    saveChanges = () => {
        let errors = [];
        if (!this.state.name) {
            errors.push("name");
        }
        if (!this.state.units) {
            errors.push("units");
        }
        if (!this.state.resultType) {
            errors.push("resultType");
        }
        if (!this.state.specimenType) {
            errors.push("specimenType");
        }
        if (!this.state.sampleType) {
            errors.push("sampleType");
        }
        this.setState({ errors: errors });
        if (errors.length > 0) {
            return false;
        }

        let analyteInfo = {
            name: this.state.name,
            loincCode: this.state.loincCode,
            units: this.state.units,
            resultType: this.state.resultType,
            method: this.state.method,
            instrument: this.state.instrument,
            turnaroundTime: this.state.turnaroundTime,
            cost: this.state.cost,
            clinicalSignificance: this.state.clinicalSignificance,
            specimenType: this.state.specimenType,
            sampleType: this.state.sampleType,
            storageTransport: this.state.storageTransport,
            referenceRanges: this.state.referenceRanges,
            criticalValues: this.state.criticalValues,
            cptCodes: this.state.cptCodes,
            synonyms: this.state.synonyms,
            interferences: this.state.interferences
        };

        if (this.state.analyteId) {
            analyteInfo._id = this.state.analyteId;
            updateAnalyte(analyteInfo).then((res) => {
                this.props.handleClose();
                this.props.context.componentParent.loadGridData();
            });
        } else {
            createAnalyte(analyteInfo).then((res) => {
                this.props.handleClose();
                this.props.context.componentParent.loadGridData();
            });
        }
    };

    render() {
        return (
            <div>
                <div className="row form-row">
                    <div className="col-12 col-md-6">
                        <div className="form-group">
                            <TextField
                                label="Name *"
                                name="name"
                                value={this.state.name}
                                onChange={this.handleChange}
                            />
                            <div
                                className={
                                    this.hasError("name")
                                        ? "inline-errormsg"
                                        : "hidden"
                                }
                            >
                                <i
                                    className="fa fa-exclamation-circle"
                                    aria-hidden="true"
                                >
                                    &nbsp;This field is required.
                                </i>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="form-group">
                            <TextField
                                label="Loinc Code"
                                name="loincCode"
                                value={this.state.loincCode}
                                onChange={this.handleChange}
                            />
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="form-group">
                            <TextField
                                select
                                label="Specimen Type *"
                                name="specimenType"
                                value={this.state.specimenType}
                                onChange={this.handleChange}
                            >
                                {specimenTypes.map((specimenType) => {
                                    return (
                                        <MenuItem
                                            value={specimenType}
                                            key={specimenType}
                                        >
                                            {specimenType}
                                        </MenuItem>
                                    );
                                })}
                            </TextField>
                            <div
                                className={
                                    this.hasError("specimenType")
                                        ? "inline-errormsg"
                                        : "hidden"
                                }
                            >
                                <i
                                    className="fa fa-exclamation-circle"
                                    aria-hidden="true"
                                >
                                    &nbsp;This field is required.
                                </i>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="form-group">
                            <TextField
                                select
                                label="Sample Type *"
                                name="sampleType"
                                value={this.state.sampleType}
                                onChange={this.handleChange}
                            >
                                {sampleTypes[this.state.specimenType] &&
                                    sampleTypes[this.state.specimenType]
                                        .length > 0 &&
                                    sampleTypes[this.state.specimenType].map(
                                        (sampleType) => {
                                            return (
                                                <MenuItem
                                                    value={sampleType}
                                                    key={sampleType}
                                                >
                                                    {sampleType}
                                                </MenuItem>
                                            );
                                        }
                                    )}
                            </TextField>
                            <div
                                className={
                                    this.hasError("sampleType")
                                        ? "inline-errormsg"
                                        : "hidden"
                                }
                            >
                                <i
                                    className="fa fa-exclamation-circle"
                                    aria-hidden="true"
                                >
                                    &nbsp;This field is required.
                                </i>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="form-group">
                            <TextField
                                label="Units *"
                                name="units"
                                value={this.state.units}
                                onChange={this.handleChange}
                            />
                            <div
                                className={
                                    this.hasError("units")
                                        ? "inline-errormsg"
                                        : "hidden"
                                }
                            >
                                <i
                                    className="fa fa-exclamation-circle"
                                    aria-hidden="true"
                                >
                                    &nbsp;This field is required.
                                </i>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="form-group">
                            <TextField
                                label="Result Type *"
                                name="resultType"
                                value={this.state.resultType}
                                onChange={this.handleChange}
                            />
                            <div
                                className={
                                    this.hasError("resultType")
                                        ? "inline-errormsg"
                                        : "hidden"
                                }
                            >
                                <i
                                    className="fa fa-exclamation-circle"
                                    aria-hidden="true"
                                >
                                    &nbsp;This field is required.
                                </i>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 input-box mb-3">
                        <div className="form-group">
                            <label>
                                Reference Ranges{" "}
                                <i
                                    className="fa fa-plus-circle btn"
                                    onClick={() =>
                                        this.addArrayItem("referenceRanges")
                                    }
                                ></i>
                            </label>
                            {this.state.referenceRanges.map(
                                (referenceRange, index) => {
                                    return (
                                        <div
                                            className="d-flex align-items-center mt-2"
                                            key={index}
                                        >
                                            <div className="border rounded w-100 p-2 pt-4">
                                                <div className="row">
                                                    <div className="col-md-4 mb-3">
                                                        <FormControl
                                                            variant="outlined"
                                                            className="w-100"
                                                        >
                                                            <InputLabel>
                                                                Sex
                                                            </InputLabel>
                                                            <Select
                                                                label="Sex"
                                                                value={
                                                                    referenceRange.sex
                                                                }
                                                                onChange={(e) =>
                                                                    this.updateArrayItem(
                                                                        e.target
                                                                            .value,
                                                                        index,
                                                                        "referenceRanges",
                                                                        "sex"
                                                                    )
                                                                }
                                                            >
                                                                <MenuItem value="MALE">
                                                                    Male
                                                                </MenuItem>
                                                                <MenuItem value="FEMALE">
                                                                    Female
                                                                </MenuItem>
                                                                <MenuItem value="BOTH">
                                                                    Both
                                                                </MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                    <div className="col-md-4 mb-3">
                                                        <FormControl
                                                            variant="outlined"
                                                            className="w-100"
                                                        >
                                                            <InputLabel>
                                                                Age Group
                                                            </InputLabel>
                                                            <Select
                                                                label="Age Group"
                                                                value={
                                                                    referenceRange.ageGroup
                                                                }
                                                                onChange={(e) =>
                                                                    this.updateArrayItem(
                                                                        e.target
                                                                            .value,
                                                                        index,
                                                                        "referenceRanges",
                                                                        "ageGroup"
                                                                    )
                                                                }
                                                            >
                                                                <MenuItem value="ADULT">
                                                                    Adult
                                                                </MenuItem>
                                                                <MenuItem value="CHILD">
                                                                    Child
                                                                </MenuItem>
                                                                <MenuItem value="BOTH">
                                                                    Both
                                                                </MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                    <div className="col-md-4 mb-3">
                                                        <TextField
                                                            label="Unit"
                                                            value={
                                                                referenceRange.unit
                                                            }
                                                            onChange={(e) =>
                                                                this.updateArrayItem(
                                                                    e.target
                                                                        .value,
                                                                    index,
                                                                    "referenceRanges",
                                                                    "unit"
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6 mb-3">
                                                        <TextField
                                                            label="Start Range"
                                                            value={
                                                                referenceRange.startRange
                                                            }
                                                            onChange={(e) =>
                                                                this.updateArrayItem(
                                                                    e.target
                                                                        .value,
                                                                    index,
                                                                    "referenceRanges",
                                                                    "startRange"
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <TextField
                                                            label="End Range"
                                                            value={
                                                                referenceRange.endRange
                                                            }
                                                            onChange={(e) =>
                                                                this.updateArrayItem(
                                                                    e.target
                                                                        .value,
                                                                    index,
                                                                    "referenceRanges",
                                                                    "endRange"
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <i
                                                className="fa fa-times-circle btn"
                                                onClick={() =>
                                                    this.removeArrayItem(
                                                        index,
                                                        "referenceRanges"
                                                    )
                                                }
                                            ></i>
                                        </div>
                                    );
                                }
                            )}
                        </div>
                    </div>
                    <div className="col-12 input-box mb-3">
                        <div className="form-group">
                            <label>
                                Critical Values{" "}
                                <i
                                    className="fa fa-plus-circle btn"
                                    onClick={() =>
                                        this.addArrayItem("criticalValues")
                                    }
                                ></i>
                            </label>
                            {this.state.criticalValues.map(
                                (criticalValue, index) => {
                                    return (
                                        <div
                                            className="d-flex align-items-center mt-2"
                                            key={index}
                                        >
                                            <div className="border rounded w-100 p-2 pt-4">
                                                <div className="row">
                                                    <div className="col-md-4 mb-3">
                                                        <FormControl
                                                            variant="outlined"
                                                            className="w-100"
                                                        >
                                                            <InputLabel>
                                                                Sex
                                                            </InputLabel>
                                                            <Select
                                                                label="Sex"
                                                                value={
                                                                    criticalValue.sex
                                                                }
                                                                onChange={(e) =>
                                                                    this.updateArrayItem(
                                                                        e.target
                                                                            .value,
                                                                        index,
                                                                        "criticalValues",
                                                                        "sex"
                                                                    )
                                                                }
                                                            >
                                                                <MenuItem value="MALE">
                                                                    Male
                                                                </MenuItem>
                                                                <MenuItem value="FEMALE">
                                                                    Female
                                                                </MenuItem>
                                                                <MenuItem value="BOTH">
                                                                    Both
                                                                </MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                    <div className="col-md-4 mb-3">
                                                        <FormControl
                                                            variant="outlined"
                                                            className="w-100"
                                                        >
                                                            <InputLabel>
                                                                Age Group
                                                            </InputLabel>
                                                            <Select
                                                                label="Age Group"
                                                                value={
                                                                    criticalValue.ageGroup
                                                                }
                                                                onChange={(e) =>
                                                                    this.updateArrayItem(
                                                                        e.target
                                                                            .value,
                                                                        index,
                                                                        "criticalValues",
                                                                        "ageGroup"
                                                                    )
                                                                }
                                                            >
                                                                <MenuItem value="ADULT">
                                                                    Adult
                                                                </MenuItem>
                                                                <MenuItem value="CHILD">
                                                                    Child
                                                                </MenuItem>
                                                                <MenuItem value="BOTH">
                                                                    Both
                                                                </MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                    <div className="col-md-4 mb-3">
                                                        <TextField
                                                            label="Unit"
                                                            value={
                                                                criticalValue.unit
                                                            }
                                                            onChange={(e) =>
                                                                this.updateArrayItem(
                                                                    e.target
                                                                        .value,
                                                                    index,
                                                                    "criticalValues",
                                                                    "unit"
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-3 mb-3">
                                                        <TextField
                                                            label="Low Value"
                                                            value={
                                                                criticalValue.lowValue
                                                            }
                                                            onChange={(e) =>
                                                                this.updateArrayItem(
                                                                    e.target
                                                                        .value,
                                                                    index,
                                                                    "criticalValues",
                                                                    "lowValue"
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                    <div className="col-md-3 mb-3">
                                                        <TextField
                                                            label="Low Operator"
                                                            value={
                                                                criticalValue.lowOperator
                                                            }
                                                            onChange={(e) =>
                                                                this.updateArrayItem(
                                                                    e.target
                                                                        .value,
                                                                    index,
                                                                    "criticalValues",
                                                                    "lowOperator"
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                    <div className="col-md-3 mb-3">
                                                        <TextField
                                                            label="High Value"
                                                            value={
                                                                criticalValue.highValue
                                                            }
                                                            onChange={(e) =>
                                                                this.updateArrayItem(
                                                                    e.target
                                                                        .value,
                                                                    index,
                                                                    "criticalValues",
                                                                    "highValue"
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                    <div className="col-md-3 mb-3">
                                                        <TextField
                                                            label="High Operator"
                                                            value={
                                                                criticalValue.highOperator
                                                            }
                                                            onChange={(e) =>
                                                                this.updateArrayItem(
                                                                    e.target
                                                                        .value,
                                                                    index,
                                                                    "criticalValues",
                                                                    "highOperator"
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <i
                                                className="fa fa-times-circle btn"
                                                onClick={() =>
                                                    this.removeArrayItem(
                                                        index,
                                                        "criticalValues"
                                                    )
                                                }
                                            ></i>
                                        </div>
                                    );
                                }
                            )}
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="form-group">
                            <TextField
                                label="Method"
                                name="method"
                                value={this.state.method}
                                onChange={this.handleChange}
                            />
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="form-group">
                            <TextField
                                label="Instrument"
                                name="instrument"
                                value={this.state.instrument}
                                onChange={this.handleChange}
                            />
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="form-group">
                            <TextField
                                label="Turnaround Time"
                                name="turnaroundTime"
                                value={this.state.turnaroundTime}
                                onChange={this.handleChange}
                            />
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="form-group">
                            <TextField
                                label="Cost"
                                name="cost"
                                value={this.state.cost}
                                onChange={this.handleChange}
                            />
                        </div>
                    </div>
                    <div className="col-12 input-box mb-3">
                        <div className="form-group">
                            <label>
                                CPT Codes{" "}
                                <i
                                    className="fa fa-plus-circle btn"
                                    onClick={() =>
                                        this.addArrayItem("cptCodes")
                                    }
                                ></i>
                            </label>
                            {this.state.cptCodes.map((cptCode, index) => {
                                return (
                                    <div className="d-flex align-items-center mt-2">
                                        <TextField
                                            key={index}
                                            value={cptCode}
                                            onChange={(e) =>
                                                this.updateArrayItem(
                                                    e.target.value,
                                                    index,
                                                    "cptCodes"
                                                )
                                            }
                                        />
                                        <i
                                            className="fa fa-times-circle btn"
                                            onClick={() =>
                                                this.removeArrayItem(
                                                    index,
                                                    "cptCodes"
                                                )
                                            }
                                        ></i>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-group">
                            <TextField
                                label="Clinical Significance"
                                name="clinicalSignificance"
                                value={this.state.clinicalSignificance}
                                onChange={this.handleChange}
                            />
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-group">
                            <TextField
                                label="Storage / Transport"
                                name="storageTransport"
                                value={this.state.storageTransport}
                                onChange={this.handleChange}
                            />
                        </div>
                    </div>
                    <div className="col-12 input-box mb-3">
                        <div className="form-group">
                            <label>
                                Synonyms{" "}
                                <i
                                    className="fa fa-plus-circle btn"
                                    onClick={() =>
                                        this.addArrayItem("synonyms")
                                    }
                                ></i>
                            </label>
                            {this.state.synonyms.map((synonym, index) => {
                                return (
                                    <div className="d-flex align-items-center mt-2">
                                        <TextField
                                            key={index}
                                            value={synonym}
                                            onChange={(e) =>
                                                this.updateArrayItem(
                                                    e.target.value,
                                                    index,
                                                    "synonyms"
                                                )
                                            }
                                        />
                                        <i
                                            className="fa fa-times-circle btn"
                                            onClick={() =>
                                                this.removeArrayItem(
                                                    index,
                                                    "synonyms"
                                                )
                                            }
                                        ></i>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className="col-12 input-box mb-3">
                        <div className="form-group">
                            <label>
                                Interferences{" "}
                                <i
                                    className="fa fa-plus-circle btn"
                                    onClick={() =>
                                        this.addArrayItem("interferences")
                                    }
                                ></i>
                            </label>
                            {this.state.interferences.map(
                                (interference, index) => {
                                    return (
                                        <div className="d-flex align-items-center mt-2">
                                            <TextField
                                                key={index}
                                                value={interference}
                                                onChange={(e) =>
                                                    this.updateArrayItem(
                                                        e.target.value,
                                                        index,
                                                        "interferences"
                                                    )
                                                }
                                            />
                                            <i
                                                className="fa fa-times-circle btn"
                                                onClick={() =>
                                                    this.removeArrayItem(
                                                        index,
                                                        "interferences"
                                                    )
                                                }
                                            ></i>
                                        </div>
                                    );
                                }
                            )}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div
                        className="col-12"
                        style={{
                            paddingTop: "10px",
                            borderTop: "1px solid rgba(0,0,0,.2"
                        }}
                    >
                        <Button
                            style={{ float: "right", marginLeft: "10px" }}
                            variant="primary"
                            onClick={this.saveChanges}
                        >
                            Save Changes
                        </Button>
                        <Button
                            style={{ float: "right" }}
                            variant="secondary"
                            onClick={this.props.handleClose}
                        >
                            Close
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

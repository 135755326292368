import React, { Component } from "react";
import { Button } from "react-bootstrap";
import {
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel
} from "@mui/material";
import {
    updateLab,
    createLab,
    getLabDataById
} from "../../../../services/clinicPortalServices/labService";
import {
    phoneNumberFormatter,
    getUserAuthToken
} from "../../../../services/common/util.js";
import { states } from "../../../../services/common/optionsData.js";
import { serviceConstants } from "../../../../services/common/constants.js";

export default class LabDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            labId: this.props && this.props.labId ? this.props.labId : "",
            name: "",
            parentCompany: "",
            logoImageFilePath: "",
            address1: "",
            address2: "",
            city: "",
            state: "",
            zip: "",
            phoneNumber: "",
            directorName: "",
            certificates: [],
            logoImageFileData: {
                fileName: "",
                content: ""
            },
            errors: []
        };
    }

    componentDidMount() {
        if (this.state.labId) {
            this.loadDetails();
        }
    }

    loadDetails = () => {
        getLabDataById(this.state.labId).then((res) => {
            const labInfo = res.data;
            this.setState({
                name: labInfo.name,
                parentCompany: labInfo.parentCompany,
                logoImageFilePath: labInfo.logoImageFilePath,
                address1:
                    labInfo && labInfo.address ? labInfo.address.address1 : "",
                address2:
                    labInfo && labInfo.address ? labInfo.address.address2 : "",
                city: labInfo && labInfo.address ? labInfo.address.city : "",
                state: labInfo && labInfo.address ? labInfo.address.state : "",
                zip: labInfo && labInfo.address ? labInfo.address.zip : "",
                phoneNumber: labInfo.phoneNumber,
                directorName: labInfo.directorName,
                certificates:
                    labInfo.certificates && labInfo.certificates.length
                        ? labInfo.certificates
                        : []
            });
        });
    };

    handleChange = (e) => {
        if (e.target.name === "phoneNumber") {
            this.setState((prevState) => ({
                phoneNumber: phoneNumberFormatter(
                    e.target.value,
                    prevState.contact_number
                )
            }));
        } else {
            this.setState({
                [e.target.name]: e.target.value
            });
        }
    };

    addCertificate = () => {
        let certificates = this.state.certificates;
        certificates.push("");
        this.setState({
            certificates: certificates
        });
    };

    updateCertificate = (value, index) => {
        let certificates = this.state.certificates;
        this.setState({
            certificates: certificates.map((item, i) => {
                if (i === index) {
                    item = value;
                }
                return item;
            })
        });
    };

    removeCertificate = (index) => {
        let certificates = this.state.certificates;
        certificates.splice(index, 1);
        this.setState({
            certificates: certificates
        });
    };

    hasError = (key) => {
        return this.state.errors.indexOf(key) !== -1;
    };

    saveChanges = () => {
        let errors = [];
        if (!this.state.name) {
            errors.push("name");
        }
        if (!this.state.address1) {
            errors.push("address1");
        }
        if (!this.state.city) {
            errors.push("city");
        }
        if (!this.state.state) {
            errors.push("state");
        }
        if (!this.state.zip) {
            errors.push("zip");
        }
        this.setState({ errors: errors });
        if (errors.length > 0) {
            return false;
        }

        let labInfo = {
            name: this.state.name,
            parentCompany: this.state.parentCompany,
            logoImageFilePath: this.state.logoImageFilePath
                ? this.state.logoImageFilePath
                : "",
            address1: this.state.address1,
            address2: this.state.address2,
            city: this.state.city,
            state: this.state.state,
            zip: this.state.zip,
            phoneNumber: this.state.phoneNumber,
            directorName: this.state.directorName,
            certificates: this.state.certificates,
            logoImageFileData:
                this.state.logoImageFileData &&
                this.state.logoImageFileData.fileName
                    ? this.state.logoImageFileData
                    : ""
        };

        if (this.state.labId) {
            labInfo._id = this.state.labId;
            updateLab(labInfo).then((res) => {
                this.props.handleClose();
                this.props.context.componentParent.loadGridData();
            });
        } else {
            createLab(labInfo).then((res) => {
                this.props.handleClose();
                this.props.context.componentParent.loadGridData();
            });
        }
    };

    onLogoImagePicker = () => {
        document.getElementById("logo-image-file").click();
    };

    onPickImage = (e) => {
        let self = this;
        const loadedFile = e.target.files[0];
        let reader = new FileReader();
        reader.onloadend = function () {
            let logoImageFileData = {
                fileName: loadedFile.name,
                content: this.result
            };
            self.setState({
                logoImageFileData: logoImageFileData,
                logoImageFilePath: this.result
            });
        };
        reader.readAsDataURL(loadedFile);
    };

    render() {
        return (
            <div>
                <div className="row form-row">
                    <div className="col-12 col-md-6">
                        <div className="form-group">
                            <TextField
                                label="Name"
                                name="name"
                                value={this.state.name}
                                onChange={this.handleChange}
                            />
                            <div
                                className={
                                    this.hasError("name")
                                        ? "inline-errormsg"
                                        : "hidden"
                                }
                            >
                                <i
                                    className="fa fa-exclamation-circle"
                                    aria-hidden="true"
                                >
                                    &nbsp;This field is required.
                                </i>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="form-group">
                            <TextField
                                label="Parent Company"
                                name="parentCompany"
                                value={this.state.parentCompany}
                                onChange={this.handleChange}
                            />
                            <div
                                className={
                                    this.hasError("parentCompany")
                                        ? "inline-errormsg"
                                        : "hidden"
                                }
                            >
                                <i
                                    className="fa fa-exclamation-circle"
                                    aria-hidden="true"
                                >
                                    &nbsp;This field is required.
                                </i>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="form-group">
                            <TextField
                                label="Address 1 *"
                                name="address1"
                                value={this.state.address1}
                                onChange={this.handleChange}
                            />
                            <div
                                className={
                                    this.hasError("address1")
                                        ? "inline-errormsg"
                                        : "hidden"
                                }
                            >
                                <i
                                    className="fa fa-exclamation-circle"
                                    aria-hidden="true"
                                >
                                    &nbsp;This field is required.
                                </i>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="form-group">
                            <TextField
                                label="Address 2"
                                name="address2"
                                value={this.state.address2}
                                onChange={this.handleChange}
                            />
                            <div
                                className={
                                    this.hasError("address2")
                                        ? "inline-errormsg"
                                        : "hidden"
                                }
                            >
                                <i
                                    className="fa fa-exclamation-circle"
                                    aria-hidden="true"
                                >
                                    &nbsp;This field is required.
                                </i>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="form-group">
                            <TextField
                                label="City *"
                                name="city"
                                value={this.state.city}
                                onChange={this.handleChange}
                            />
                            <div
                                className={
                                    this.hasError("city")
                                        ? "inline-errormsg"
                                        : "hidden"
                                }
                            >
                                <i
                                    className="fa fa-exclamation-circle"
                                    aria-hidden="true"
                                >
                                    &nbsp;This field is required.
                                </i>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="form-group">
                            <FormControl
                                variant="outlined"
                                style={{ width: "100%" }}
                            >
                                <InputLabel>State</InputLabel>
                                <Select
                                    value={this.state.state}
                                    onChange={this.handleChange}
                                    label="State"
                                    name="state"
                                >
                                    {states.map((state, index) => {
                                        return (
                                            <MenuItem
                                                key={state.value}
                                                value={state.value}
                                            >
                                                {state.state}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                            <div
                                className={
                                    this.hasError("state")
                                        ? "inline-errormsg"
                                        : "hidden"
                                }
                            >
                                <i
                                    className="fa fa-exclamation-circle"
                                    aria-hidden="true"
                                >
                                    &nbsp;This field is required.
                                </i>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="form-group">
                            <TextField
                                label="Zip Code *"
                                name="zip"
                                value={this.state.zip}
                                onChange={this.handleChange}
                            />
                            <div
                                className={
                                    this.hasError("zip")
                                        ? "inline-errormsg"
                                        : "hidden"
                                }
                            >
                                <i
                                    className="fa fa-exclamation-circle"
                                    aria-hidden="true"
                                >
                                    &nbsp;This field is required.
                                </i>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="form-group">
                            <TextField
                                label="Phone Number"
                                name="phoneNumber"
                                value={this.state.phoneNumber}
                                onChange={this.handleChange}
                            />
                            <div
                                className={
                                    this.hasError("phoneNumber")
                                        ? "inline-errormsg"
                                        : "hidden"
                                }
                            >
                                <i
                                    className="fa fa-exclamation-circle"
                                    aria-hidden="true"
                                >
                                    &nbsp;This field is required.
                                </i>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="form-group">
                            <TextField
                                label="Director Name"
                                name="directorName"
                                value={this.state.directorName}
                                onChange={this.handleChange}
                            />
                            <div
                                className={
                                    this.hasError("directorName")
                                        ? "inline-errormsg"
                                        : "hidden"
                                }
                            >
                                <i
                                    className="fa fa-exclamation-circle"
                                    aria-hidden="true"
                                >
                                    &nbsp;This field is required.
                                </i>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 input-box">
                        <div className="form-group">
                            <label>
                                Certificates or Certifications{" "}
                                <i
                                    className="fa fa-plus-circle btn"
                                    onClick={() => this.addCertificate()}
                                ></i>
                            </label>
                            {this.state.certificates.map(
                                (certificate, index) => {
                                    return (
                                        <div className="d-flex align-items-center mt-2">
                                            <TextField
                                                key={index}
                                                value={certificate}
                                                onChange={(e) =>
                                                    this.updateCertificate(
                                                        e.target.value,
                                                        index
                                                    )
                                                }
                                            />
                                            <i
                                                className="fa fa-times-circle btn"
                                                onClick={() =>
                                                    this.removeCertificate(
                                                        index
                                                    )
                                                }
                                            ></i>
                                        </div>
                                    );
                                }
                            )}
                        </div>
                    </div>
                    <div className="col-12 mt-3">
                        <div className="card notifyType_imageBar">
                            <button
                                className="nofityType_imagePicker"
                                onClick={this.onLogoImagePicker}
                            >
                                Upload Logo Image
                            </button>
                            {this.state.logoImageFilePath && (
                                <img
                                    className="mt-3"
                                    src={
                                        this.state.logoImageFileData.fileName
                                            ? this.state.logoImageFilePath
                                            : serviceConstants.API_HOST_NAME +
                                              this.state.logoImageFilePath +
                                              "?" +
                                              new Date().getTime() +
                                              "&token=" +
                                              getUserAuthToken()
                                    }
                                    alt="Logo"
                                />
                            )}
                            <input
                                className="d-none"
                                type="file"
                                id="logo-image-file"
                                onChange={this.onPickImage}
                                accept="image/,.png"
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div
                        className="col-12"
                        style={{
                            paddingTop: "10px",
                            borderTop: "1px solid rgba(0,0,0,.2"
                        }}
                    >
                        <Button
                            style={{ float: "right", marginLeft: "10px" }}
                            variant="primary"
                            onClick={this.saveChanges}
                        >
                            Save Changes
                        </Button>
                        <Button
                            style={{ float: "right" }}
                            variant="secondary"
                            onClick={this.props.handleClose}
                        >
                            Close
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}
